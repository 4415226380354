import {createRootRoute, Outlet, ScrollRestoration} from '@tanstack/react-router';
import {lazy, Suspense} from 'react';

export const Route = createRootRoute({
  component: RouteComponent,
});

const RouterDevTools =
  process.env.NODE === 'production'
    ? () => null
    : lazy(() =>
        import('@tanstack/router-devtools').then(({TanStackRouterDevtools}) => ({default: TanStackRouterDevtools}))
      );

function RouteComponent() {
  return (
    <>
      <ScrollRestoration />
      <Suspense>
        <RouterDevTools />
      </Suspense>
      <Outlet />
    </>
  );
}
