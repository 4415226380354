/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthorizedImport } from './routes/_authorized'
import { Route as SendResetPasswordRouteImport } from './routes/sendResetPassword/route'
import { Route as ResetPasswordRouteImport } from './routes/resetPassword/route'
import { Route as InviteMemberRouteImport } from './routes/inviteMember/route'
import { Route as ApplicationDraftRouteImport } from './routes/application-draft/route'
import { Route as AuthorizedIndexImport } from './routes/_authorized/index'
import { Route as SsoAuthImport } from './routes/sso/auth'
import { Route as SsoCodeImport } from './routes/sso/$code'
import { Route as AuthorizedSplatImport } from './routes/_authorized/$'
import { Route as SsoSignupRouteImport } from './routes/sso/signup/route'
import { Route as AuthorizedLayoutIndexImport } from './routes/_authorized/layout/index'
import { Route as AuthorizedLayoutFloorIdIndexImport } from './routes/_authorized/layout/$floorId/index'
import { Route as AuthorizedKeysNfcIndexImport } from './routes/_authorized/keys/nfc/index'
import { Route as AuthorizedMobileReservationIndexImport } from './routes/_authorized/_mobile/reservation/index'
import { Route as AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport } from './routes/_authorized/layout/$floorId/$layoutCompositionId/index'
import { Route as AuthorizedLayoutFloorIdLayoutCompositionIdEditImport } from './routes/_authorized/layout/$floorId/$layoutCompositionId/edit'
import { Route as AuthorizedMobileFormSatisfactionCompleteImport } from './routes/_authorized/_mobile/form/satisfaction/complete'
import { Route as AuthorizedMobileFormSatisfactionSatisfactionIdImport } from './routes/_authorized/_mobile/form/satisfaction/$satisfactionId'

// Create/Update Routes

const AuthorizedRoute = AuthorizedImport.update({
  id: '/_authorized',
  getParentRoute: () => rootRoute,
} as any)

const SendResetPasswordRouteRoute = SendResetPasswordRouteImport.update({
  id: '/sendResetPassword',
  path: '/sendResetPassword',
  getParentRoute: () => rootRoute,
} as any)

const ResetPasswordRouteRoute = ResetPasswordRouteImport.update({
  id: '/resetPassword',
  path: '/resetPassword',
  getParentRoute: () => rootRoute,
} as any)

const InviteMemberRouteRoute = InviteMemberRouteImport.update({
  id: '/inviteMember',
  path: '/inviteMember',
  getParentRoute: () => rootRoute,
} as any)

const ApplicationDraftRouteRoute = ApplicationDraftRouteImport.update({
  id: '/application-draft',
  path: '/application-draft',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedIndexRoute = AuthorizedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthorizedRoute,
} as any)

const SsoAuthRoute = SsoAuthImport.update({
  id: '/sso/auth',
  path: '/sso/auth',
  getParentRoute: () => rootRoute,
} as any)

const SsoCodeRoute = SsoCodeImport.update({
  id: '/sso/$code',
  path: '/sso/$code',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedSplatRoute = AuthorizedSplatImport.update({
  id: '/$',
  path: '/$',
  getParentRoute: () => AuthorizedRoute,
} as any)

const SsoSignupRouteRoute = SsoSignupRouteImport.update({
  id: '/sso/signup',
  path: '/sso/signup',
  getParentRoute: () => rootRoute,
} as any)

const AuthorizedLayoutIndexRoute = AuthorizedLayoutIndexImport.update({
  id: '/layout/',
  path: '/layout/',
  getParentRoute: () => AuthorizedRoute,
} as any)

const AuthorizedLayoutFloorIdIndexRoute =
  AuthorizedLayoutFloorIdIndexImport.update({
    id: '/layout/$floorId/',
    path: '/layout/$floorId/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedKeysNfcIndexRoute = AuthorizedKeysNfcIndexImport.update({
  id: '/keys/nfc/',
  path: '/keys/nfc/',
  getParentRoute: () => AuthorizedRoute,
} as any)

const AuthorizedMobileReservationIndexRoute =
  AuthorizedMobileReservationIndexImport.update({
    id: '/_mobile/reservation/',
    path: '/reservation/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute =
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport.update({
    id: '/layout/$floorId/$layoutCompositionId/',
    path: '/layout/$floorId/$layoutCompositionId/',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute =
  AuthorizedLayoutFloorIdLayoutCompositionIdEditImport.update({
    id: '/layout/$floorId/$layoutCompositionId/edit',
    path: '/layout/$floorId/$layoutCompositionId/edit',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedMobileFormSatisfactionCompleteRoute =
  AuthorizedMobileFormSatisfactionCompleteImport.update({
    id: '/_mobile/form/satisfaction/complete',
    path: '/form/satisfaction/complete',
    getParentRoute: () => AuthorizedRoute,
  } as any)

const AuthorizedMobileFormSatisfactionSatisfactionIdRoute =
  AuthorizedMobileFormSatisfactionSatisfactionIdImport.update({
    id: '/_mobile/form/satisfaction/$satisfactionId',
    path: '/form/satisfaction/$satisfactionId',
    getParentRoute: () => AuthorizedRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/application-draft': {
      id: '/application-draft'
      path: '/application-draft'
      fullPath: '/application-draft'
      preLoaderRoute: typeof ApplicationDraftRouteImport
      parentRoute: typeof rootRoute
    }
    '/inviteMember': {
      id: '/inviteMember'
      path: '/inviteMember'
      fullPath: '/inviteMember'
      preLoaderRoute: typeof InviteMemberRouteImport
      parentRoute: typeof rootRoute
    }
    '/resetPassword': {
      id: '/resetPassword'
      path: '/resetPassword'
      fullPath: '/resetPassword'
      preLoaderRoute: typeof ResetPasswordRouteImport
      parentRoute: typeof rootRoute
    }
    '/sendResetPassword': {
      id: '/sendResetPassword'
      path: '/sendResetPassword'
      fullPath: '/sendResetPassword'
      preLoaderRoute: typeof SendResetPasswordRouteImport
      parentRoute: typeof rootRoute
    }
    '/_authorized': {
      id: '/_authorized'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthorizedImport
      parentRoute: typeof rootRoute
    }
    '/sso/signup': {
      id: '/sso/signup'
      path: '/sso/signup'
      fullPath: '/sso/signup'
      preLoaderRoute: typeof SsoSignupRouteImport
      parentRoute: typeof rootRoute
    }
    '/_authorized/$': {
      id: '/_authorized/$'
      path: '/$'
      fullPath: '/$'
      preLoaderRoute: typeof AuthorizedSplatImport
      parentRoute: typeof AuthorizedImport
    }
    '/sso/$code': {
      id: '/sso/$code'
      path: '/sso/$code'
      fullPath: '/sso/$code'
      preLoaderRoute: typeof SsoCodeImport
      parentRoute: typeof rootRoute
    }
    '/sso/auth': {
      id: '/sso/auth'
      path: '/sso/auth'
      fullPath: '/sso/auth'
      preLoaderRoute: typeof SsoAuthImport
      parentRoute: typeof rootRoute
    }
    '/_authorized/': {
      id: '/_authorized/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthorizedIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/': {
      id: '/_authorized/layout/'
      path: '/layout'
      fullPath: '/layout'
      preLoaderRoute: typeof AuthorizedLayoutIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/_mobile/reservation/': {
      id: '/_authorized/_mobile/reservation/'
      path: '/reservation'
      fullPath: '/reservation'
      preLoaderRoute: typeof AuthorizedMobileReservationIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/keys/nfc/': {
      id: '/_authorized/keys/nfc/'
      path: '/keys/nfc'
      fullPath: '/keys/nfc'
      preLoaderRoute: typeof AuthorizedKeysNfcIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/': {
      id: '/_authorized/layout/$floorId/'
      path: '/layout/$floorId'
      fullPath: '/layout/$floorId'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdIndexImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/_mobile/form/satisfaction/$satisfactionId': {
      id: '/_authorized/_mobile/form/satisfaction/$satisfactionId'
      path: '/form/satisfaction/$satisfactionId'
      fullPath: '/form/satisfaction/$satisfactionId'
      preLoaderRoute: typeof AuthorizedMobileFormSatisfactionSatisfactionIdImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/_mobile/form/satisfaction/complete': {
      id: '/_authorized/_mobile/form/satisfaction/complete'
      path: '/form/satisfaction/complete'
      fullPath: '/form/satisfaction/complete'
      preLoaderRoute: typeof AuthorizedMobileFormSatisfactionCompleteImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/$layoutCompositionId/edit': {
      id: '/_authorized/layout/$floorId/$layoutCompositionId/edit'
      path: '/layout/$floorId/$layoutCompositionId/edit'
      fullPath: '/layout/$floorId/$layoutCompositionId/edit'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditImport
      parentRoute: typeof AuthorizedImport
    }
    '/_authorized/layout/$floorId/$layoutCompositionId/': {
      id: '/_authorized/layout/$floorId/$layoutCompositionId/'
      path: '/layout/$floorId/$layoutCompositionId'
      fullPath: '/layout/$floorId/$layoutCompositionId'
      preLoaderRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexImport
      parentRoute: typeof AuthorizedImport
    }
  }
}

// Create and export the route tree

interface AuthorizedRouteChildren {
  AuthorizedSplatRoute: typeof AuthorizedSplatRoute
  AuthorizedIndexRoute: typeof AuthorizedIndexRoute
  AuthorizedLayoutIndexRoute: typeof AuthorizedLayoutIndexRoute
  AuthorizedMobileReservationIndexRoute: typeof AuthorizedMobileReservationIndexRoute
  AuthorizedKeysNfcIndexRoute: typeof AuthorizedKeysNfcIndexRoute
  AuthorizedLayoutFloorIdIndexRoute: typeof AuthorizedLayoutFloorIdIndexRoute
  AuthorizedMobileFormSatisfactionSatisfactionIdRoute: typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  AuthorizedMobileFormSatisfactionCompleteRoute: typeof AuthorizedMobileFormSatisfactionCompleteRoute
  AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute: typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

const AuthorizedRouteChildren: AuthorizedRouteChildren = {
  AuthorizedSplatRoute: AuthorizedSplatRoute,
  AuthorizedIndexRoute: AuthorizedIndexRoute,
  AuthorizedLayoutIndexRoute: AuthorizedLayoutIndexRoute,
  AuthorizedMobileReservationIndexRoute: AuthorizedMobileReservationIndexRoute,
  AuthorizedKeysNfcIndexRoute: AuthorizedKeysNfcIndexRoute,
  AuthorizedLayoutFloorIdIndexRoute: AuthorizedLayoutFloorIdIndexRoute,
  AuthorizedMobileFormSatisfactionSatisfactionIdRoute:
    AuthorizedMobileFormSatisfactionSatisfactionIdRoute,
  AuthorizedMobileFormSatisfactionCompleteRoute:
    AuthorizedMobileFormSatisfactionCompleteRoute,
  AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute:
    AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute,
  AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute:
    AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute,
}

const AuthorizedRouteWithChildren = AuthorizedRoute._addFileChildren(
  AuthorizedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '': typeof AuthorizedRouteWithChildren
  '/sso/signup': typeof SsoSignupRouteRoute
  '/$': typeof AuthorizedSplatRoute
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/': typeof AuthorizedIndexRoute
  '/layout': typeof AuthorizedLayoutIndexRoute
  '/reservation': typeof AuthorizedMobileReservationIndexRoute
  '/keys/nfc': typeof AuthorizedKeysNfcIndexRoute
  '/layout/$floorId': typeof AuthorizedLayoutFloorIdIndexRoute
  '/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/layout/$floorId/$layoutCompositionId': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRoutesByTo {
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '/sso/signup': typeof SsoSignupRouteRoute
  '/$': typeof AuthorizedSplatRoute
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/': typeof AuthorizedIndexRoute
  '/layout': typeof AuthorizedLayoutIndexRoute
  '/reservation': typeof AuthorizedMobileReservationIndexRoute
  '/keys/nfc': typeof AuthorizedKeysNfcIndexRoute
  '/layout/$floorId': typeof AuthorizedLayoutFloorIdIndexRoute
  '/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/layout/$floorId/$layoutCompositionId': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/application-draft': typeof ApplicationDraftRouteRoute
  '/inviteMember': typeof InviteMemberRouteRoute
  '/resetPassword': typeof ResetPasswordRouteRoute
  '/sendResetPassword': typeof SendResetPasswordRouteRoute
  '/_authorized': typeof AuthorizedRouteWithChildren
  '/sso/signup': typeof SsoSignupRouteRoute
  '/_authorized/$': typeof AuthorizedSplatRoute
  '/sso/$code': typeof SsoCodeRoute
  '/sso/auth': typeof SsoAuthRoute
  '/_authorized/': typeof AuthorizedIndexRoute
  '/_authorized/layout/': typeof AuthorizedLayoutIndexRoute
  '/_authorized/_mobile/reservation/': typeof AuthorizedMobileReservationIndexRoute
  '/_authorized/keys/nfc/': typeof AuthorizedKeysNfcIndexRoute
  '/_authorized/layout/$floorId/': typeof AuthorizedLayoutFloorIdIndexRoute
  '/_authorized/_mobile/form/satisfaction/$satisfactionId': typeof AuthorizedMobileFormSatisfactionSatisfactionIdRoute
  '/_authorized/_mobile/form/satisfaction/complete': typeof AuthorizedMobileFormSatisfactionCompleteRoute
  '/_authorized/layout/$floorId/$layoutCompositionId/edit': typeof AuthorizedLayoutFloorIdLayoutCompositionIdEditRoute
  '/_authorized/layout/$floorId/$layoutCompositionId/': typeof AuthorizedLayoutFloorIdLayoutCompositionIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | ''
    | '/sso/signup'
    | '/$'
    | '/sso/$code'
    | '/sso/auth'
    | '/'
    | '/layout'
    | '/reservation'
    | '/keys/nfc'
    | '/layout/$floorId'
    | '/form/satisfaction/$satisfactionId'
    | '/form/satisfaction/complete'
    | '/layout/$floorId/$layoutCompositionId/edit'
    | '/layout/$floorId/$layoutCompositionId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | '/sso/signup'
    | '/$'
    | '/sso/$code'
    | '/sso/auth'
    | '/'
    | '/layout'
    | '/reservation'
    | '/keys/nfc'
    | '/layout/$floorId'
    | '/form/satisfaction/$satisfactionId'
    | '/form/satisfaction/complete'
    | '/layout/$floorId/$layoutCompositionId/edit'
    | '/layout/$floorId/$layoutCompositionId'
  id:
    | '__root__'
    | '/application-draft'
    | '/inviteMember'
    | '/resetPassword'
    | '/sendResetPassword'
    | '/_authorized'
    | '/sso/signup'
    | '/_authorized/$'
    | '/sso/$code'
    | '/sso/auth'
    | '/_authorized/'
    | '/_authorized/layout/'
    | '/_authorized/_mobile/reservation/'
    | '/_authorized/keys/nfc/'
    | '/_authorized/layout/$floorId/'
    | '/_authorized/_mobile/form/satisfaction/$satisfactionId'
    | '/_authorized/_mobile/form/satisfaction/complete'
    | '/_authorized/layout/$floorId/$layoutCompositionId/edit'
    | '/_authorized/layout/$floorId/$layoutCompositionId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  ApplicationDraftRouteRoute: typeof ApplicationDraftRouteRoute
  InviteMemberRouteRoute: typeof InviteMemberRouteRoute
  ResetPasswordRouteRoute: typeof ResetPasswordRouteRoute
  SendResetPasswordRouteRoute: typeof SendResetPasswordRouteRoute
  AuthorizedRoute: typeof AuthorizedRouteWithChildren
  SsoSignupRouteRoute: typeof SsoSignupRouteRoute
  SsoCodeRoute: typeof SsoCodeRoute
  SsoAuthRoute: typeof SsoAuthRoute
}

const rootRouteChildren: RootRouteChildren = {
  ApplicationDraftRouteRoute: ApplicationDraftRouteRoute,
  InviteMemberRouteRoute: InviteMemberRouteRoute,
  ResetPasswordRouteRoute: ResetPasswordRouteRoute,
  SendResetPasswordRouteRoute: SendResetPasswordRouteRoute,
  AuthorizedRoute: AuthorizedRouteWithChildren,
  SsoSignupRouteRoute: SsoSignupRouteRoute,
  SsoCodeRoute: SsoCodeRoute,
  SsoAuthRoute: SsoAuthRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/application-draft",
        "/inviteMember",
        "/resetPassword",
        "/sendResetPassword",
        "/_authorized",
        "/sso/signup",
        "/sso/$code",
        "/sso/auth"
      ]
    },
    "/application-draft": {
      "filePath": "application-draft/route.tsx"
    },
    "/inviteMember": {
      "filePath": "inviteMember/route.tsx"
    },
    "/resetPassword": {
      "filePath": "resetPassword/route.tsx"
    },
    "/sendResetPassword": {
      "filePath": "sendResetPassword/route.tsx"
    },
    "/_authorized": {
      "filePath": "_authorized.tsx",
      "children": [
        "/_authorized/$",
        "/_authorized/",
        "/_authorized/layout/",
        "/_authorized/_mobile/reservation/",
        "/_authorized/keys/nfc/",
        "/_authorized/layout/$floorId/",
        "/_authorized/_mobile/form/satisfaction/$satisfactionId",
        "/_authorized/_mobile/form/satisfaction/complete",
        "/_authorized/layout/$floorId/$layoutCompositionId/edit",
        "/_authorized/layout/$floorId/$layoutCompositionId/"
      ]
    },
    "/sso/signup": {
      "filePath": "sso/signup/route.tsx"
    },
    "/_authorized/$": {
      "filePath": "_authorized/$.tsx",
      "parent": "/_authorized"
    },
    "/sso/$code": {
      "filePath": "sso/$code.tsx"
    },
    "/sso/auth": {
      "filePath": "sso/auth.tsx"
    },
    "/_authorized/": {
      "filePath": "_authorized/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/": {
      "filePath": "_authorized/layout/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/_mobile/reservation/": {
      "filePath": "_authorized/_mobile/reservation/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/keys/nfc/": {
      "filePath": "_authorized/keys/nfc/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/": {
      "filePath": "_authorized/layout/$floorId/index.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/_mobile/form/satisfaction/$satisfactionId": {
      "filePath": "_authorized/_mobile/form/satisfaction/$satisfactionId.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/_mobile/form/satisfaction/complete": {
      "filePath": "_authorized/_mobile/form/satisfaction/complete.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/$layoutCompositionId/edit": {
      "filePath": "_authorized/layout/$floorId/$layoutCompositionId/edit.tsx",
      "parent": "/_authorized"
    },
    "/_authorized/layout/$floorId/$layoutCompositionId/": {
      "filePath": "_authorized/layout/$floorId/$layoutCompositionId/index.tsx",
      "parent": "/_authorized"
    }
  }
}
ROUTE_MANIFEST_END */
