import dayjs from 'dayjs';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {BusinessType, BusinessTypeState} from '../redux/state-types/businessTypeStateType';
import {Locale, LocaleState} from '../redux/state-types/localeStateType';
import {RootState} from '../redux/store/rootStateType';
import {TimeUtils} from '../utils/timeUtils';

export const LocaleData: {[locale in Locale]: {label: string; alt?: Locale}} = {
  ja_JP: {label: '日本語'},
  en_US: {label: 'English, US'},
};

const DEFAULT_DISPLAY_CARDS_NUMBER = 3;

export type DefaultLocaleDictDef = {default: any};
export type LocaleDictDef = {[K in Locale]?: any};
export type DefaultBusinessDictDef = {default: DefaultLocaleDictDef & LocaleDictDef};
export type BusinessDictDef = {[K in BusinessType]?: DefaultLocaleDictDef & LocaleDictDef};
export type DictDef = {
  [DictKey: string]: DefaultBusinessDictDef & BusinessDictDef;
};
export type Dict<T extends DictDef> = {
  [K in keyof T]: any;
};

export const createDict = <T extends DictDef>(
  dictDef: DictDef,
  businessType: BusinessType,
  locale: Locale
): Dict<T> => {
  const dict: Dict<T> = {} as Dict<T>;
  for (const dictKey of Object.keys(dictDef)) {
    let biz = dictDef[dictKey][businessType];
    if (!biz) {
      biz = dictDef[dictKey].default;
    }
    const k: keyof T = dictKey;
    if (!locale) {
      dict[k] = biz.default;
    } else {
      let word = biz[locale];
      if (word !== undefined) {
        dict[k] = word;
      } else {
        const alt = LocaleData[locale].alt;
        if (alt) {
          word = biz[alt];
          if (word) {
            dict[k] = word;
          } else {
            dict[k] = biz.default;
          }
        } else {
          dict[k] = biz.default;
        }
      }
    }
  }
  return dict;
};

const useDict = <T extends DictDef>(dictDef: T): Dict<T> => {
  const businessType = useSelector<RootState, BusinessTypeState>(state => state.businessType);
  const locale = useSelector<RootState, LocaleState>(state => state.locale);
  return useMemo(() => createDict<T>(dictDef, businessType, locale), [businessType, dictDef, locale]);
};

export const commonDictDef = {
  noData: {
    default: {
      default: '-',
      [Locale.en_US]: '-',
    },
  },
  noHitsMessage: {
    default: {
      default: '該当するデータがありません。',
      [Locale.en_US]: 'No hits message.',
    },
  },
  formatMilliseconds: {
    default: {
      default: (millis: EpochMillis) =>
        millis ? dayjs(millis).format('YYYY/MM/DD') + ' ' + dayjs(millis).format('HH:mm') : '-',
      [Locale.en_US]: (millis: EpochMillis) =>
        millis ? dayjs(millis).format('DD/MM/YYYY') + ' ' + dayjs(millis).format('HH:mm') : '-',
    },
  },
  urlPrefix: {
    default: {
      default: 'https://',
      [Locale.en_US]: 'https://',
    },
  },
  register: {
    default: {
      default: '登録',
      [Locale.en_US]: 'Register',
    },
  },
  newCreate: {
    default: {
      default: '新規作成',
      [Locale.en_US]: 'Create',
    },
  },
  noChange: {
    default: {
      default: '変更なし',
      [Locale.en_US]: 'No Change',
    },
  },
  update: {
    default: {
      default: '更新',
      [Locale.en_US]: 'Update',
    },
  },
  edit: {
    default: {
      default: '編集',
      [Locale.en_US]: 'Edit',
    },
  },
  delete: {
    default: {
      default: '削除',
      [Locale.en_US]: 'Delete',
    },
  },
  cancel: {
    default: {
      default: 'キャンセル',
      [Locale.en_US]: 'Cancel',
    },
  },
  name: {
    default: {
      default: '氏名',
      [Locale.en_US]: 'Name',
    },
  },
  nameKana: {
    default: {
      default: '氏名(カナ)',
      [Locale.en_US]: 'Name Kana',
    },
  },
  emailAddress: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email Address',
    },
  },
  address: {
    default: {
      default: '住所',
      [Locale.en_US]: 'Address',
    },
  },
  phoneNumber: {
    default: {
      default: '電話番号',
      [Locale.en_US]: 'Phone number',
    },
  },
  createdAt: {
    default: {
      default: '登録日時',
      [Locale.en_US]: 'Created at',
    },
  },
  lastUpdatedAt: {
    default: {
      default: '最終更新日時',
      [Locale.en_US]: 'Last updated at',
    },
  },
  unspecified: {
    default: {
      default: '指定なし',
      [Locale.en_US]: 'Unspecified',
    },
  },
  download: {
    default: {
      default: 'ダウンロード',
      [Locale.en_US]: 'Download',
    },
  },
  complete: {
    default: {
      default: '完了',
      [Locale.en_US]: 'Complete',
    },
  },
  back: {
    default: {
      default: '戻る',
      [Locale.en_US]: 'Back',
    },
  },
  next: {
    default: {
      default: '次へ',
      [Locale.en_US]: 'Next',
    },
  },
  add: {
    default: {
      default: '追加',
      [Locale.en_US]: 'Add',
    },
  },
  save: {
    default: {
      default: '保存',
      [Locale.en_US]: 'Save',
    },
  },
  cancellation: {
    default: {
      default: '取消',
      [Locale.en_US]: 'Cancellation',
    },
  },
  close: {
    default: {
      default: '閉じる',
      [Locale.en_US]: 'Close',
    },
  },
  filter: {
    default: {
      default: '絞込み',
      [Locale.en_US]: 'Filter',
    },
  },
  search: {
    default: {
      default: '検索',
      [Locale.en_US]: 'Search',
    },
  },
  send: {
    default: {
      default: '送信',
      [Locale.en_US]: 'Send',
    },
  },
  validationOfCode: {
    default: {
      default: '半角英数または記号(-_()[].)で入力してください',
      [Locale.en_US]: 'Enter in half-width alphanumeric characters.',
    },
  },
  noSettings: {
    default: {
      default: '設定なし',
      [Locale.en_US]: 'No Settings',
    },
  },
  sun: {
    default: {
      default: '日',
      [Locale.en_US]: 'Sun',
    },
  },
  mon: {
    default: {
      default: '月',
      [Locale.en_US]: 'Mon',
    },
  },
  tue: {
    default: {
      default: '火',
      [Locale.en_US]: 'Tue',
    },
  },
  wed: {
    default: {
      default: '水',
      [Locale.en_US]: 'Wed',
    },
  },
  thu: {
    default: {
      default: '木',
      [Locale.en_US]: 'Thu',
    },
  },
  fri: {
    default: {
      default: '金',
      [Locale.en_US]: 'Fri',
    },
  },
  sat: {
    default: {
      default: '土',
      [Locale.en_US]: 'Sat',
    },
  },
  // 性別
  Female: {
    default: {
      default: '女性',
      [Locale.en_US]: 'Female',
    },
  },
  Male: {
    default: {
      default: '男性',
      [Locale.en_US]: 'Male',
    },
  },
  //未入力項目のエラーメッセージ
  empty: {
    default: {
      default: (item: string) => `${item}が未入力です`,
      [Locale.en_US]: (item: string) => `${item} is empty`,
    },
  },
  holiday: {
    default: {
      default: '祝',
      [Locale.en_US]: 'Holiday',
    },
  },
  member: {
    default: {
      default: '一般会員',
      [Locale.en_US]: 'Member',
    },
  },
  payer: {
    default: {
      default: '支払い担当',
      [Locale.en_US]: 'Payer',
    },
  },
  memberManager: {
    default: {
      default: '会員管理担当',
      [Locale.en_US]: 'Member Manager',
    },
  },
  manager: {
    default: {
      default: '管理者',
      [Locale.en_US]: 'Manager',
    },
  },
  confirm: {
    default: {
      default: '確認',
      [Locale.en_US]: 'Confirm',
    },
  },
  day: {
    default: {
      default: '日',
      [Locale.en_US]: 'days',
    },
  },
  month: {
    default: {
      default: 'ヶ月',
      [Locale.en_US]: 'months',
    },
  },
  week: {
    default: {
      default: '週間',
      [Locale.en_US]: 'weeks',
    },
  },
  year: {
    default: {
      default: '年',
      [Locale.en_US]: 'years',
    },
  },
  // ~日など数値として表すものではなく単純な単位の名称としての定義
  unitDay: {
    default: {
      default: '日',
      [Locale.en_US]: 'day',
    },
  },
  unitMonth: {
    default: {
      default: '月',
      [Locale.en_US]: 'month',
    },
  },
  unitWeek: {
    default: {
      default: '週',
      [Locale.en_US]: 'week',
    },
  },
  unitYear: {
    default: {
      default: '年',
      [Locale.en_US]: 'year',
    },
  },
  // 省略形3文字は重複する可能性があると思ったのでprefixでm(month)つけました
  mJan: {
    default: {
      default: '1月',
      [Locale.en_US]: 'Jan',
    },
  },
  mFeb: {
    default: {
      default: '2月',
      [Locale.en_US]: 'Feb',
    },
  },
  mMar: {
    default: {
      default: '3月',
      [Locale.en_US]: 'Mar',
    },
  },
  mApr: {
    default: {
      default: '4月',
      [Locale.en_US]: 'Apr',
    },
  },
  mMay: {
    default: {
      default: '5月',
      [Locale.en_US]: 'May',
    },
  },
  mJun: {
    default: {
      default: '6月',
      [Locale.en_US]: 'Jun',
    },
  },
  mJul: {
    default: {
      default: '7月',
      [Locale.en_US]: 'Jul',
    },
  },
  mAug: {
    default: {
      default: '8月',
      [Locale.en_US]: 'Aug',
    },
  },
  mSep: {
    default: {
      default: '9月',
      [Locale.en_US]: 'Sep',
    },
  },
  mOct: {
    default: {
      default: '10月',
      [Locale.en_US]: 'Oct',
    },
  },
  mNov: {
    default: {
      default: '11月',
      [Locale.en_US]: 'Nov',
    },
  },
  mDec: {
    default: {
      default: '12月',
      [Locale.en_US]: 'Dec',
    },
  },

  // ここからしたはV1使っているからとりあえず定義しているやつ
  // TODO v1削除時にこっちも消す
  failureDataExists: {
    default: {
      default: '登録に失敗しました',
      [Locale.en_US]: 'Registration failed',
    },
  },
  registeredSuccessfully: {
    default: {
      default: '登録に成功しました',
      [Locale.en_US]: 'Successfully registered',
    },
  },
  results: {
    default: {
      default: '結果',
      [Locale.en_US]: 'result',
    },
  },
  noSetting: {
    default: {
      default: '設定なし',
      [Locale.en_US]: 'No Setting',
    },
  },
  countUnit: {
    default: {
      default: (pageCount: number, allCount: number) => `${pageCount} / ${allCount}件`,
      [Locale.en_US]: (pageCount: number, allCount: number) => `${pageCount} / ${allCount}`,
    },
  },
  destruction: {
    default: {
      default: '破棄',
      [Locale.en_US]: 'Destruction',
    },
  },
  select: {
    default: {
      default: '選択',
      [Locale.en_US]: 'Select',
    },
  },
  overview: {
    default: {
      default: '概要',
      [Locale.en_US]: 'Overview',
    },
  },
  csvDownload: {
    default: {
      default: 'CSV出力',
      [Locale.en_US]: 'CSV download',
    },
  },
  notActive: {
    default: {
      default: '招待中',
      [Locale.en_US]: 'Not active',
    },
  },
  active: {
    default: {
      default: '参加済み',
      [Locale.en_US]: 'Active',
    },
  },
  resendInvitation: {
    default: {
      default: '招待メール再送信',
      [Locale.en_US]: 'Resend invitation',
    },
  },
  unResendInvitationPeople: {
    default: {
      default: '入金待ちの契約があるため、招待メールの再送信に失敗した利用者がいます',
      [Locale.en_US]: 'Failed to resend invitation mail for some people because they have unpaid contracts',
    },
  },
  resend: {
    default: {
      default: '再送信',
      [Locale.en_US]: 'Resend',
    },
  },
  notRegister: {
    default: {
      default: '未登録',
      [Locale.en_US]: 'Not register',
    },
  },
  loading: {
    default: {
      default: 'データを取得しています',
      [Locale.en_US]: 'loading',
    },
  },
  backToList: {
    default: {
      default: '一覧へ戻る',
      [Locale.en_US]: 'Back to list',
    },
  },
  temporarySave: {
    default: {
      default: '一時保存',
      [Locale.en_US]: 'Temporary Save',
    },
  },
  deleted: {
    default: {
      default: '(削除済)',
      [Locale.en_US]: '(Deleted)',
    },
  },
  retired: {
    default: {
      default: '(退職済)',
      [Locale.en_US]: '(retired)',
    },
  },
  scheduledToRetire: {
    default: {
      default: '(退職予定)',
      [Locale.en_US]: '(retirement plans)',
    },
  },
  hours: {
    default: {
      default: '時間',
      [Locale.en_US]: 'hours',
    },
  },
  minutes: {
    default: {
      default: '分',
      [Locale.en_US]: 'minutes',
    },
  },
  // ここまで

  daysText: {
    default: {
      default: ['日', '月', '火', '水', '木', '金', '土'],
      [Locale.en_US]: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    },
  },
  department: {
    default: {
      default: '部門',
      [Locale.en_US]: 'Department',
    },
  },
  employeeGroups: {
    default: {
      default: '従業員グループ',
      [Locale.en_US]: 'Employee Groups',
    },
  },
  employmentStatus: {
    default: {
      default: '雇用形態',
      [Locale.en_US]: 'Employment Status',
    },
  },
  position: {
    default: {
      default: '役職',
      [Locale.en_US]: 'Position',
    },
  },
  required: {
    default: {
      default: '必須',
      [Locale.en_US]: 'Required',
    },
  },
  validationOfRequired: {
    default: {
      default: '必須項目です',
      [Locale.en_US]: 'Required fields',
    },
  },
  optional: {
    default: {
      default: '任意',
      [Locale.en_US]: 'Optional',
    },
  },

  // 共通的エラーダイアログ
  failedDialogTitle: {
    default: {
      default: (text: string) => `${text}に失敗しました`,
      [Locale.en_US]: (text: string) => `Failed to ${text}`,
    },
  },

  // 日付
  invalidDate: {
    default: {
      default: '日付が不正です',
      [Locale.en_US]: 'The date is incorrect',
    },
  },
};

export const spaceDictDef = {
  region: {
    default: {
      default: '拠点',
      [Locale.en_US]: 'Region',
    },
  },
  regionName: {
    default: {
      default: '拠点名',
      [Locale.en_US]: 'Region name',
    },
  },
  noRegion: {
    default: {
      default: '指定なし（指定する場合は拠点を登録してください。）',
      [Locale.en_US]: 'Not specified (If you want to specify, please register the base.)',
    },
  },
  placeholderRegion: {
    default: {
      default: '拠点を選択してください',
      [Locale.en_US]: 'Please select a region',
    },
  },
  building: {
    default: {
      default: 'ビル',
      [Locale.en_US]: 'Building',
    },
  },
  buildingName: {
    default: {
      default: 'ビル名',
      [Locale.en_US]: 'Building name',
    },
  },
  placeholderNoBuilding: {
    default: {
      default: '指定なし（指定する場合はビルを登録してください。）',
      [Locale.en_US]: 'Not specified (If you want to specify, please register the building.)',
    },
  },
  placeholderBuilding: {
    default: {
      default: 'ビルを選択してください',
      [Locale.en_US]: 'Please select a building',
    },
  },
  floor: {
    default: {
      default: 'フロア',
      [Locale.en_US]: 'Floor',
    },
  },
  floorName: {
    default: {
      default: 'フロア名',
      [Locale.en_US]: 'Floor name',
    },
  },
  placeholderNoFloor: {
    default: {
      default: '指定なし (指定する場合はフロアを登録してください)',
      [Locale.en_US]: 'No specification (please register the floor)',
    },
  },
  placeholderFloor: {
    default: {
      default: 'フロアを選択してください',
      [Locale.en_US]: 'Please select a floor',
    },
  },
  parentArea: {
    default: {
      default: '親エリア',
      [Locale.en_US]: 'Parent Area',
    },
  },
  parentAreaName: {
    default: {
      default: '親エリア名',
      [Locale.en_US]: 'Parent Area name',
    },
  },
  placeholderParentArea: {
    default: {
      default: '親エリアを選択してください',
      [Locale.en_US]: 'Please select a parent Area',
    },
  },
  area: {
    default: {
      default: 'エリア',
      [Locale.en_US]: 'Area',
    },
  },
  areaName: {
    default: {
      default: 'エリア名',
      [Locale.en_US]: 'Area name',
    },
  },
  placeholderArea: {
    default: {
      default: 'エリアを選択してください',
      [Locale.en_US]: 'Please select a area',
    },
  },
  workspot: {
    default: {
      default: 'ワークスポット',
      [Locale.en_US]: 'Workspot',
    },
  },
  workspotName: {
    default: {
      default: 'ワークスポット名',
      [Locale.en_US]: 'Workspot name',
    },
  },
  meetingRoom: {
    default: {
      default: '会議室',
      [Locale.en_US]: 'Meeting Room',
    },
  },
  openSpace: {
    default: {
      default: 'オープンスペース',
      [Locale.en_US]: 'Open Space',
    },
  },
  facility: {
    default: {
      default: '施設',
      [Locale.en_US]: 'Facility',
    },
  },
  parking: {
    default: {
      default: '駐車場・駐輪場',
      [Locale.en_US]: 'Parking',
    },
  },
  workspace: {
    default: {
      default: '執務エリア',
      [Locale.en_US]: 'Workspace',
    },
  },
  reception: {
    default: {
      default: '受付エリア',
      [Locale.en_US]: 'Reception Area',
    },
  },
  commonArea: {
    default: {
      default: '共用部',
      [Locale.en_US]: 'Common Area',
    },
  },
  exclusiveArea: {
    default: {
      default: '専有部',
      [Locale.en_US]: 'Exclusive Area',
    },
  },
  staffOnly: {
    default: {
      default: 'スタッフルーム',
      [Locale.en_US]: 'Staff Only',
    },
  },
  workBooth: {
    default: {
      default: 'ワークブース',
      [Locale.en_US]: 'Work Booth',
    },
  },
  privateRoom: {
    default: {
      default: '個室',
      [Locale.en_US]: 'Private Room',
    },
  },
  other: {
    default: {
      default: 'その他のエリア',
      [Locale.en_US]: 'Other',
    },
  },
  floorMap: {
    default: {
      default: 'フロアマップ',
      [Locale.en_US]: 'Floor Map',
    },
  },
  info: {
    default: {
      default: '基本情報',
      [Locale.en_US]: 'Basic info',
    },
  },
  image: {
    default: {
      default: '画像',
      [Locale.en_US]: 'Image',
    },
  },
  nameJp: {
    default: {
      default: '名称',
      [Locale.en_US]: 'Name',
    },
  },
  nameEn: {
    default: {
      default: '名称（EN）',
      [Locale.en_US]: 'Name(EN)',
    },
  },
  code: {
    default: {
      default: 'コード',
      [Locale.en_US]: 'Code.',
    },
  },
  codeDuplicated: {
    default: {
      default: '登録されているコードです',
      [Locale.en_US]: 'Registered code.',
    },
  },
  memo: {
    default: {
      default: 'メモ',
      [Locale.en_US]: 'Memo',
    },
  },
  loading: {
    default: {
      default: '空間情報を取得中です...',
      [Locale.en_US]: 'Loading...',
    },
  },
};

const peopleDictDef = {
  employeeManagement: {
    default: {
      default: '従業員管理 /',
      [Locale.en_US]: 'Employee Management',
    },
  },
  employee: {
    default: {
      default: '従業員',
      [Locale.en_US]: 'Employees',
    },
  },
  editBasicInfo: {
    default: {
      default: '基本情報を編集',
      [Locale.en_US]: 'Role edit',
    },
  },
  editEmployeeInfo: {
    default: {
      default: '人事情報を編集',
      [Locale.en_US]: 'Role edit',
    },
  },
  editRole: {
    default: {
      default: '役割を編集',
      [Locale.en_US]: 'Role edit',
    },
  },
  editEmployeeGroups: {
    default: {
      default: '従業員グループを編集',
      [Locale.en_US]: 'Employee Groups edit',
    },
  },
  editReceptionSetting: {
    default: {
      default: '受付設定を編集',
      [Locale.en_US]: 'Role edit',
    },
  },
  editExternalId: {
    default: {
      default: '外部サービスIDを編集',
      [Locale.en_US]: 'External Id edit',
    },
  },

  // ボタン
  uploadCsv: {
    default: {
      default: 'CSV取り込み',
      [Locale.en_US]: 'Upload CSV',
    },
  },
  downloadCsv: {
    default: {
      default: 'CSV出力',
      [Locale.en_US]: 'Download CSV',
    },
  },
  add: {
    default: {
      default: '新規追加',
      [Locale.en_US]: 'Add',
    },
  },
  reRun: {
    default: {
      default: '再実行',
      [Locale.en_US]: 'Rerun',
    },
  },
  send: {
    default: {
      default: '送信',
      [Locale.en_US]: 'Send',
    },
  },
  sendInvitation: {
    default: {
      default: '招待メール送信',
      [Locale.en_US]: 'Send invitation',
    },
  },
  bulkEditButtonLabel: {
    default: {
      default: '人事情報の編集',
      [Locale.en_US]: 'Editing employee information',
    },
  },
  // 検索条件
  searchableName: {
    default: {
      default: '氏名、メールアドレスなど',
      [Locale.en_US]: 'name or email address',
    },
  },
  officeFacetName: {
    default: {
      default: '事業所名',
      [Locale.en_US]: 'office name',
    },
  },
  departmentFacetName: {
    default: {
      default: '部門名',
      [Locale.en_US]: 'department name',
    },
  },
  faceRegistrationStatus: {
    default: {
      default: '顔情報',
      [Locale.en_US]: 'Face Registration',
    },
  },
  status: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  // ステータス
  notInviting: {
    default: {
      default: '未招待',
      [Locale.en_US]: 'Not inviting',
    },
  },
  inviting: {
    default: {
      default: '招待中',
      [Locale.en_US]: 'Inviting',
    },
  },
  invitationExpired: {
    default: {
      default: '招待期限切れ',
      [Locale.en_US]: 'Invitation Expired',
    },
  },
  uploadingCsv: {
    default: {
      default: 'CSV取り込み中',
      [Locale.en_US]: 'Uploading',
    },
  },
  uploadCsvError: {
    default: {
      default: 'CSV取り込みエラー',
      [Locale.en_US]: 'Upload error',
    },
  },
  inTheJob: {
    default: {
      default: '在籍中',
      [Locale.en_US]: 'In the job',
    },
  },
  scheduledToRetire: {
    default: {
      default: '退職予定',
      [Locale.en_US]: 'Scheduled to retire',
    },
  },
  retired: {
    default: {
      default: '退職済',
      [Locale.en_US]: 'Retired',
    },
  },
  // 顔情報
  faceRegistrationActiveStatusWidth: {
    default: {
      default: 150,
      [Locale.en_US]: 400,
    },
  },
  faceRegistered: {
    default: {
      default: 'あり',
      [Locale.en_US]: 'Registerd',
    },
  },
  faceNotRegistered: {
    default: {
      default: 'なし',
      [Locale.en_US]: 'Not registerd',
    },
  },
  faceDelete: {
    default: {
      default: '顔情報の削除',
      [Locale.en_US]: 'Delete Face',
    },
  },
  successFaceDeleteMessage: {
    default: {
      default: '顔情報の削除に成功しました',
      [Locale.en_US]: 'Succeeded to delete face',
    },
  },
  failedFaceDeleteMessage: {
    default: {
      default: '顔情報の削除に失敗しました',
      [Locale.en_US]: 'Failed to delete face',
    },
  },
  // 従業員詳細
  personInfo: {
    default: {
      default: '基本情報',
      [Locale.en_US]: 'Person info',
    },
  },

  officeDepartment: {
    default: {
      default: '事業所・部門',
      [Locale.en_US]: 'Office/Department',
    },
  },
  code: {
    default: {
      default: '従業員番号',
      [Locale.en_US]: 'Employee code',
    },
  },
  office: {
    default: {
      default: '事業所',
      [Locale.en_US]: 'Office',
    },
  },
  department: {
    default: {
      default: '部門',
      [Locale.en_US]: 'Department',
    },
  },
  // メッセージ
  uploadCsvErrorMessage: {
    default: {
      default: 'システムのエラーにより、CSV取り込みに失敗したデータがあります。再度取り込みをお試しください。',
      [Locale.en_US]: 'Some data failed to import to CSV due to a system error. Please try importing again.',
    },
  },
  reRunDescription: {
    default: {
      default: 'CSV取り込みに失敗しました。\n\n読込みを再度実行する場合は、「再実行」ボタンを押してください。',
      [Locale.en_US]: 'Failed to import CSV. \n\nIf you want to reload, press the "Rerun" button.',
    },
  },
  deleteMessageTitle: {
    default: {
      default: '従業員の削除',
      [Locale.en_US]: 'Delete employees',
    },
  },
  deleteMessage: {
    default: {
      default: '従業員を削除すると、その従業員の解錠権限も削除されます。',
      [Locale.en_US]: 'If you delete a people, it will no longer be available.',
    },
  },
  youWillBeLoggedOut: {
    default: {
      default: 'また、自分自身を削除するとその後ログアウトされ、ログインできなくなります。',
      [Locale.en_US]: 'Also, after deleting yourself, you will be logged out and unable to login.',
    },
  },
  areYouSure: {
    default: {
      default: '本当に削除してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to delete it?',
    },
  },
  successDeleteMessage: {
    default: {
      default: '従業員情報の削除に成功しました',
      [Locale.en_US]: 'Succeeded to delete employee information',
    },
  },
  failedDeleteMessage: {
    default: {
      default: '従業員情報の削除に失敗しました',
      [Locale.en_US]: 'Failed to delete employee information',
    },
  },
  sendInvitationMessage: {
    default: {
      default: '選択した従業員に招待メールを送信します\n\n※既に在籍中の従業員には、送信されません',
      [Locale.en_US]: 'Send invitations to selected employees\n\nNot sent to already active users',
    },
  },
  sendInvitationError: {
    default: {
      default: '送信エラー',
      [Locale.en_US]: `Send Error`,
    },
  },
  sendInvitationErrorMessage: {
    default: {
      default: '選択した従業員は既にアクティブな為、送信できません',
      [Locale.en_US]: 'The selected employee is already active and cannot be sent',
    },
  },
  successSendMessage: {
    default: {
      default: '招待に成功しました',
      [Locale.en_US]: 'Success to send invitations',
    },
  },
  failedSendMessage: {
    default: {
      default: '招待に失敗しました',
      [Locale.en_US]: 'Failed to send invitations',
    },
  },
  cannotDeletedSystemAdminMessage: {
    default: {
      default: 'システム管理者は１名以上登録されている必要があるため削除できませんでした。',
      [Locale.en_US]: 'The system administrator could not deleted because at least one person must be registered.',
    },
  },
  cannotDeleteSamlLoginUserMessage: {
    default: {
      default: 'SAMLログインを設定しているアカウントはworkhubからの操作では削除できません。',
      [Locale.en_US]: 'SAML login account is not possible to delete from workhub.',
    },
  },
  rerunSuccess: {
    default: {
      default: '処理の再実行中です。しばらくお待ちください。',
      [Locale.en_US]: 'Re-running. Please wait a moment',
    },
  },
  rerunFailed: {
    default: {
      default: '処理の再実行に失敗しました',
      [Locale.en_US]: 'Failed to re-run',
    },
  },
  requireServiceIdTitle: {
    default: {
      default: `登録必須の外部サービスIDが未登録のユーザーが存在します`,
      [Locale.en_US]: 'Require service id',
    },
  },
  requireServiceIdContent: {
    default: {
      default: (text: string) => `の${text}が未登録です`,
      [Locale.en_US]: (text: string) => `'s ${text} is not registered`,
    },
  },
  // 従業員情報
  personName: {
    default: {
      default: '氏名',
      [Locale.en_US]: 'Name (JP)',
    },
  },
  personNameKana: {
    default: {
      default: '氏名（カナ）',
      [Locale.en_US]: 'Name (Katakana)',
    },
  },
  personNameEn: {
    default: {
      default: '氏名（EN）',
      [Locale.en_US]: 'Name',
    },
  },
  birthday: {
    default: {
      default: '生年月日',
      [Locale.en_US]: 'birthday',
    },
  },
  email: {
    default: {
      default: 'メールアドレス',
      [Locale.en_US]: 'Email',
    },
  },
  employmentStatus: {
    default: {
      default: '雇用形態',
      [Locale.en_US]: 'Employment status',
    },
  },
  personPosition: {
    default: {
      default: '役職',
      [Locale.en_US]: 'Official position',
    },
  },
  note: {
    default: {
      default: '備考',
      [Locale.en_US]: 'Note',
    },
  },
  registerDate: {
    default: {
      default: '登録日',
      [Locale.en_US]: 'Register date',
    },
  },
  gender: {
    default: {
      default: '性別',
      [Locale.en_US]: 'Gender',
    },
  },
  phoneNumber: {
    default: {
      default: '電話番号',
      [Locale.en_US]: 'Phone number',
    },
  },
  emergencyPhoneNumber: {
    default: {
      default: '緊急時連絡先',
      [Locale.en_US]: 'Emergency phone number',
    },
  },
  identification: {
    default: {
      default: '本人確認',
      [Locale.en_US]: 'Identification',
    },
  },
  externalIdTitle: {
    default: {
      default: '外部サービスID',
      [Locale.en_US]: 'external Id',
    },
  },
  externalIdError: {
    default: {
      default: '登録必須の外部サービスIDが1件未登録です。',
      [Locale.en_US]: 'external Id',
    },
  },
  faceTimeId: {
    default: {
      default: 'FaceTime Id',
      [Locale.en_US]: 'FaceTime Id',
    },
  },
  slackId: {
    default: {
      default: 'Slack Id',
      [Locale.en_US]: 'Slack Id',
    },
  },
  workplaceFromMetaId: {
    default: {
      default: 'Workplace Id',
      [Locale.en_US]: 'Workplace Id',
    },
  },
  wowtalkId: {
    default: {
      default: 'WowTalk Id',
      [Locale.en_US]: 'WowTalk Id',
    },
  },
  googleChatId: {
    default: {
      default: 'Google Chat Id',
      [Locale.en_US]: 'Google Chat Id',
    },
  },
  office365Id: {
    default: {
      default: 'Office 365 Id',
      [Locale.en_US]: 'Office 365 Id',
    },
  },
  teamsId: {
    default: {
      default: 'Teams Id',
      [Locale.en_US]: 'Teams Id',
    },
  },
  slackChannel: {
    default: {
      default: 'ゲスト到着通知のSlackチャンネル',
      [Locale.en_US]: 'Slack Channel for guest arrival',
    },
  },
  slackChannelEmpty: {
    default: {
      default: '通知先チャンネルが指定されていないため、組織で共通の通知先チャンネルに通知されます。',
      [Locale.en_US]: 'There is no channel setting for you, so guest arrival will be notified to common channel.',
    },
  },
  notificationLabelDM: {
    default: {
      default: 'DM',
      [Locale.en_US]: 'DM',
    },
  },

  superUser: {
    default: {
      default: 'システム管理者',
      [Locale.en_US]: 'Super User',
    },
  },
  employeeStatus: {
    default: {
      default: 'ステータス',
      [Locale.en_US]: 'Status',
    },
  },
  visitorGroup: {
    default: {
      default: '来訪者グループ',
      [Locale.en_US]: 'Visitor Group',
    },
  },
  vendorGroup: {
    default: {
      default: '業者グループ',
      [Locale.en_US]: 'Vendor Group',
    },
  },
  companyName: {
    default: {
      default: '所属名',
      [Locale.en_US]: 'Company Name',
    },
  },
  iconImage: {
    default: {
      default: '画像',
      [Locale.en_US]: 'Icon Image',
    },
  },
  authority: {
    default: {
      default: '権限',
      [Locale.en_US]: 'authority',
    },
  },
  occupation: {
    default: {
      default: '職種',
      [Locale.en_US]: 'Type of occupation',
    },
  },
  position: {
    default: {
      default: '役職',
      [Locale.en_US]: 'Position',
    },
  },
  workspace: {
    default: {
      default: '勤務地',
      [Locale.en_US]: 'Workspace',
    },
  },
  role: {
    default: {
      default: '役割',
      [Locale.en_US]: 'Role',
    },
  },
  employeeCode: {
    default: {
      default: '従業員番号',
      [Locale.en_US]: 'Employee code',
    },
  },
  codeDuplicated: {
    default: {
      default: '登録されているコードです',
      [Locale.en_US]: 'Registered code.',
    },
  },
  selectEmployeeGroup: {
    default: {
      default: '従業員グループを選択してください',
      [Locale.en_US]: 'Select a employee group.',
    },
  },
  employeeGroup: {
    default: {
      default: '従業員グループ',
      [Locale.en_US]: 'Employee group',
    },
  },
  employeeGroupName: {
    default: {
      default: '従業員グループ名称',
      [Locale.en_US]: 'Employee group name',
    },
  },
  nothingRole: {
    default: {
      default: '役割が設定されていません。',
      [Locale.en_US]: 'No role has been set.',
    },
  },
  nothingPosition: {
    default: {
      default: '役職が設定されていません。',
      [Locale.en_US]: 'No position has been set.',
    },
  },
  availablePeriod: {
    default: {
      default: '利用可能期間',
      [Locale.en_US]: 'Available period',
    },
  },
  availablePeriodFrom: {
    default: {
      default: '利用可能期間(開始)',
      [Locale.en_US]: 'Available period(From)',
    },
  },
  availablePeriodTo: {
    default: {
      default: '利用可能期間(終了)',
      [Locale.en_US]: 'Available period(To)',
    },
  },
  personnelInfo: {
    default: {
      default: '人事情報',
      [Locale.en_US]: 'Personnel information',
    },
  },
  confirmStatusAlertTitle: {
    default: {
      default: 'ステータス変更確認',
      [Locale.en_US]: 'Confirmation Of Status Change',
    },
  },
  employeeStatusInTheJobAlertDescription: {
    default: {
      default: 'ステータスを在籍中に変更してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to change your status to In the job?',
    },
  },
  employeeStatusScheduledToRetireAlertDescription: {
    default: {
      default: 'ステータスを退職予定に変更してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to change your status to Scheduled to retire?',
    },
  },
  employeeStatusRetiredAlertDescription: {
    default: {
      default: 'ステータスを退職済に変更してよろしいですか？',
      [Locale.en_US]: 'Are you sure you want to change your status to Retired?',
    },
  },
  maxDateMessage: {
    default: {
      default: '入力した日時が最大日時を超えています。',
      [Locale.en_US]: 'Date should not be after maximal date.',
    },
  },
  emptyMessageForRetired: {
    default: {
      default: '退職済の場合は終了日を入力してください。',
      [Locale.en_US]: 'If already retired, please enter a past date.',
    },
  },
  emptyMessageForScheduledToRetire: {
    default: {
      default: '退職予定の場合は終了日を入力してください。',
      [Locale.en_US]: 'If you plan to retire, please enter a future date.',
    },
  },
  maxDateMessageForRetired: {
    default: {
      default: '退職済の場合は過去の日付を入力してください。',
      [Locale.en_US]: 'If already retired, please enter a past date.',
    },
  },
  maxDateMessageForScheduleRetired: {
    default: {
      default: '退職予定の場合は未来の日付を入力してください。',
      [Locale.en_US]: 'If you plan to retire, please enter a future date.',
    },
  },
  validTermFromPlaceHolder: {
    default: {
      default: '開始日',
      [Locale.en_US]: 'Start date',
    },
  },
  validTermToPlaceHolder: {
    default: {
      default: '終了日',
      [Locale.en_US]: 'End date',
    },
  },
  validTermToOverFromErrorMessage: {
    default: {
      default: '開始日は終了日より前の日付を指定してください',
      [Locale.en_US]: 'The start date should be a date before the end date',
    },
  },
  invalidValidTermDateMessage: {
    default: {
      default: '日付が不正です',
      [Locale.en_US]: 'The date is incorrect',
    },
  },
  validTermFromIsAfterCurrentPeoplesTo: {
    default: {
      default: '設定中の開始日以前に利用を終了する従業員を含んでいます',
      [Locale.en_US]: 'Includes employees who ends available period prior to the start date being set',
    },
  },
  validTermToIsBeforeCurrentPeoplesFrom: {
    default: {
      default: '設定中の終了日以降に利用を開始する従業員を含んでいます',
      [Locale.en_US]: 'Includes employees who starts available period later to the end date being set',
    },
  },
  notJoinedEmployeesExistMessage: {
    default: {
      default: '不参加の従業員が含まれています。除外するか、招待を完了させてください。',
      [Locale.en_US]: 'Non-participating employees are included. Please exclude them or complete the invitation.',
    },
  },
  receptionSetting: {
    default: {
      default: '受付設定',
      [Locale.en_US]: 'Reception Settings',
    },
  },
  emptyRole: {
    default: {
      default: '役割は割り当てられていません',
      [Locale.en_US]: 'No role assigned.',
    },
  },
  emptyUserGroups: {
    default: {
      default: '従業員グループに所属していません',
      [Locale.en_US]: 'Not belonging to any employee group.',
    },
  },
  emptySecurityCard: {
    default: {
      default: '所有しているもしくは貸出中のセキュリティカードはありません',
      [Locale.en_US]: 'not have a security card that I own or rent.',
    },
  },
  owningCards: {
    default: {
      default: '所有中',
      [Locale.en_US]: 'Employee Id Cards You Own',
    },
  },
  hasOtherEmployeeIdCards: {
    default: {
      default: (num: number) => `他、${num - DEFAULT_DISPLAY_CARDS_NUMBER}枚のカードを所有中です`,
      [Locale.en_US]: (num: number) => `You own ${num - DEFAULT_DISPLAY_CARDS_NUMBER} other employee cards.`,
    },
  },
  hasOtherTemporaryUseCards: {
    default: {
      default: (num: number) => `他、${num - DEFAULT_DISPLAY_CARDS_NUMBER}枚の貸出中カードがあります`,
      [Locale.en_US]: (num: number) =>
        `You have borrowed ${num - DEFAULT_DISPLAY_CARDS_NUMBER} other temporary-use cards.`,
    },
  },
  lendLimitPeriod: {
    default: {
      default: (lendLimitPeriod: number) => `${TimeUtils.timestampToFormatString(dayjs(lendLimitPeriod))} まで`,
      [Locale.en_US]: (lendLimitPeriod: number) =>
        `Return By ${TimeUtils.timestampToFormatString(dayjs(lendLimitPeriod))}`,
    },
  },
  employeeIdDialogTitle: {
    default: {
      default: '所有中の社員証',
      [Locale.en_US]: 'Employee Id Cards You Own',
    },
  },
  temporaryUseCardDialogTitle: {
    default: {
      default: '一時貸出中のセキュリティカード',
      [Locale.en_US]: 'Temporary Use Cards You Have Borrowed',
    },
  },
};

const deviceDictDef = {
  accessPoint: {
    default: {
      default: 'アクセスポイント',
      [Locale.en_US]: 'Access Point',
    },
  },
  bitlockSeries: {
    default: {
      default: 'bitlockシリーズ',
      [Locale.en_US]: 'bitlock Series',
    },
  },
  controlPanel: {
    default: {
      default: '制御盤連携デバイス',
      [Locale.en_US]: 'ControlPanel Integration Device',
    },
  },
  electricLock: {
    default: {
      default: '電気錠',
      [Locale.en_US]: 'Electric Lock',
    },
  },
  entrance: {
    default: {
      default: 'エントランス',
      [Locale.en_US]: 'Entrance',
    },
  },
  faceRecognitionTablets: {
    default: {
      default: '顔認証タブレット',
      [Locale.en_US]: 'Face Recognition',
    },
  },
  flapperGates: {
    default: {
      default: 'フラッパーゲート',
      [Locale.en_US]: 'Flapper Gate',
    },
  },
  reader: {
    default: {
      default: 'リーダー',
      [Locale.en_US]: 'Reader',
    },
  },
  reception: {
    default: {
      default: 'レセプション',
      [Locale.en_US]: 'Reception',
    },
  },
  roomSupport: {
    default: {
      default: 'Room Support',
      [Locale.en_US]: 'Room Support',
    },
  },
  peopleCountCamera: {
    default: {
      default: 'クラウドカメラ',
      [Locale.en_US]: 'Cloud Camera',
    },
  },
  locker: {
    default: {
      default: 'ロッカー',
      [Locale.en_US]: 'Locker',
    },
  },
  flapperGate: {
    default: {
      default: 'フラッパーゲート',
      [Locale.en_US]: 'Flapper Gate',
    },
  },
};

const peopleTypeDictDef = {
  member: {
    default: {
      default: '従業員',
      [Locale.en_US]: 'Employee',
    },
  },
  visitor: {
    default: {
      default: '来訪者',
      [Locale.en_US]: 'Visitor',
    },
  },
  customer: {
    default: {
      default: '取引先',
      [Locale.en_US]: 'Customer',
    },
  },
  cast: {
    default: {
      default: '業者',
      [Locale.en_US]: 'Cast',
    },
  },
  thirdPlaceCustomer: {
    default: {
      default: '会員',
      [Locale.en_US]: 'Member',
    },
  },
};

const nfcCardDictDef = {
  title: {
    default: {
      default: 'セキュリティカード',
      [Locale.en_US]: 'Security card',
    },
  },
  temporaryUse: {
    default: {
      default: '一時貸出中',
      [Locale.en_US]: 'Temporary Use Cards',
    },
  },
  noDueDate: {
    default: {
      default: '指定された返却日はありません',
      [Locale.en_US]: 'No Return-by Date',
    },
  },
};

/**
 * 解錠方法
 */
const unlockMethodDictDef = {
  oneTimePasscode: {
    default: {
      default: 'ワンタイムパスコード',
      [Locale.en_US]: 'One-time passcode',
    },
  },
  qrCode: {
    default: {
      default: 'QRコード',
      [Locale.en_US]: 'QR code',
    },
  },
  faceRecognition: {
    default: {
      default: '顔認証',
      [Locale.en_US]: 'Face recognition',
    },
  },
  workhubApp: {
    default: {
      default: 'アプリ解錠',
      [Locale.en_US]: 'App unlock',
    },
  },
};

/**
 * 認証関連のメッセージ
 */
const authDictDef = {
  loginErrorMessage: {
    default: {
      default: (errorCode?: string, isLocked?: boolean) => {
        if (isLocked) {
          return 'アカウントがロックされています。';
        }
        switch (errorCode) {
          case 'workhub-usage-not-allowed':
            return '利用が停止されています。利用再開を希望される場合は、下記までお問い合わせください。\nsupport_work@bitkey.jp';
          case 'ip-not-accessible':
            return '接続が許可されていません';
          case 'no-authority-available':
            return '管理画面を利用する権限がありません。';
          case 'unmatch-organization-id':
            return '招待されていない組織です';
          default:
            return 'メールアドレスまたはパスワードが違います';
        }
      },
      [Locale.en_US]: (errorCode?: string, isLocked?: boolean) => {
        if (isLocked) {
          return 'Your account is locked. ';
        }
        switch (errorCode) {
          case 'workhub-usage-not-allowed':
            return 'workhub usage is suspended. Please contact Bitkey to resume.';
          case 'ip-not-accessible':
            return 'Access not allowed';
          case 'no-authority-available':
            return 'No feature available.';
          case 'unmatch-organization-id':
            return 'Not access organization.';
          default:
            return 'Incorrect email address or password.';
        }
      },
    },
  },
};

const paymentMethodDictDef = {
  creditCard: {
    default: {
      default: 'クレジットカード',
      [Locale.en_US]: 'Credit card',
    },
  },
  invoice: {
    default: {
      default: '請求書払い',
      [Locale.en_US]: 'Invoice',
    },
  },
  bankAccountTransfer: {
    default: {
      default: '口座振替',
      [Locale.en_US]: 'Bank Account Transfer',
    },
  },
};

export const useCommonDict = () => {
  return useDict<typeof commonDictDef>(commonDictDef);
};

export const useSpaceDict = () => {
  return useDict<typeof spaceDictDef>(spaceDictDef);
};

export const usePeopleDict = () => {
  return useDict<typeof peopleDictDef>(peopleDictDef);
};

export const useDeviceDict = () => {
  return useDict<typeof deviceDictDef>(deviceDictDef);
};

export const usePeopleTypeDict = () => {
  return useDict<typeof peopleTypeDictDef>(peopleTypeDictDef);
};

export const useAreaTypeDict = () => {
  return useDict<typeof spaceDictDef>(spaceDictDef);
};

export const useNfcCardDictDef = () => {
  return useDict<typeof nfcCardDictDef>(nfcCardDictDef);
};

export const useAuthDict = () => {
  return useDict<typeof authDictDef>(authDictDef);
};

export const useUnlockMethodDict = () => {
  return useDict<typeof unlockMethodDictDef>(unlockMethodDictDef);
};
export const usePaymentMethodDictDef = () => {
  return useDict<typeof paymentMethodDictDef>(paymentMethodDictDef);
};

export default useDict;
