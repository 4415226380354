import {FsActivation} from '@/firestore/types/fsActivationType';

import {ValueOf} from '../utils/typeUtils';

export const Feature = {
  Announcement: 'announcement',
  Mypage: 'mypage',
  Dashboard: 'dashboard',
  Building: 'building',
  CompanyInformation: 'companyInformation',
  Bitlock: 'bitlock',
  BitlockGate: 'bitlockGate',
  Bitreader: 'bitreader',
  WifiConnector: 'wifiConnector',
  Bitlink: 'bitlink',
  FaceTablet: 'faceTablet',
  SecurityCamera: 'securityCamera', // 顔検出用カメラ (他にもあるかもなので)
  Floor: 'floor',
  Room: 'room',
  // 表示設定は後で
  Face: 'face', // 顔情報一覧とか
  Nfc: 'nfc',
  // NfcGroup: 'nfcGroup', // グループ見れるなら個別のNFCも見れるんちゃう、と思う。これくらいは一緒でいいかも
  UnlockConditionTemplate: 'unlockConditionTemplate',
  // 勤怠と入退室は別じゃない？と思う。勤怠は何をもって出勤とするか、っていう設定を別で持って、そっちに特化した画面になる感じ。
  Attendance: 'attendance',
  EntryExit: 'entryExit',
  Users: 'users', // ユーザー一覧を全部見れる。すぐに細分化したくなる気がするけど取り急ぎ。やるとしても詳細設定でいいかも。フィルタ設定的な。
  Authority: 'authority', // この中でも自分の権限以上の権限は扱えない制御が必要。機能追加時に死ぬのでフル権限っていうのは別で作る。
  UnlockLog: 'unlockLog',
  Reception: 'reception',
  ReceptionSetting: 'receptionSetting',
  LinkageSetting: 'linkageSetting',
  SSOSetting: 'SSOSetting',
  ReceptionSettingTemplate: 'receptionSettingTemplate',
  CloudCamera: 'cloudCamera',
  ReservationCalendar: 'reservationCalendar',
  ReservationCalendarAddPeopleCsv: 'reservationCalendarAddPeopleCsv',
  ReservationCalendarMySchedule: 'reservationCalendarMySchedule',
  ReservationThingCalendar: 'reservationThingCalendar',
  ReservationLog: 'reservationLog',
  CoworkingReservationCalendar: 'coworkingReservationCalendar',
  ReservationSetting: 'reservationSetting',
  CongestionStatus: 'congestionStatus',
  Subscriptions: 'subscriptions',
  EventReservation: 'eventReservation',
  EventReservationParticipants: 'eventReservationParticipants',
  EventReservationList: 'eventReservationList',
  EventReservationDashboard: 'EventReservationDashboard',
  // ここからV2
  V2Regions: 'v2Regions',
  V2Buildings: 'v2Buildings',
  V2Floors: 'v2Floors',
  V2Areas: 'v2Areas',
  V2Workspots: 'v2Workspots',
  V2Layouts: 'v2Layouts',
  AntiPassBackArea: 'antiPassBackArea',
  AntiPassBackAlert: 'antiPassBackAlert',
  V2SecurityDevices: 'v2SecurityDevices',
  V2SecurityDeviceAlert: 'v2SecurityDeviceAlert',
  V2SecurityCards: 'v2SecurityCards',
  V2TemporaryCardGroups: 'v2TemporaryCardGroups',
  V2SecurityCardGroups: 'v2SecurityCardGroups',
  V2Equipments: 'v2Equipments',
  V2Lockers: 'V2Lockers',
  // ロッカー予約用の権限。Appで明確に権限を分けたいのでわけました
  V2LockerReservationCalendar: 'v2LockerReservationCalendar',
  Shelves: 'shelves',
  V2Members: 'v2Members',
  V2OfficeDepartments: 'v2OfficeDepartments',
  V2Positions: 'v2Positions',
  V2EmploymentStatuses: 'v2EmploymentStatuses',
  V2EmployeeGroupSettings: 'v2EmployeeGroupSettings',
  V2EmployeesAttendance: 'v2EmployeesAttendance',
  V2LocationSearch: 'v2LocationSearch',
  V2Visitors: 'v2Visitors',
  V2Casts: 'v2Casts',
  V2CastGroups: 'v2CastGroups',
  V2Customers: 'v2Customers',
  V2AccessRights: 'v2AccessRights',
  PasscodeGroups: 'PasscodeGroups',
  V2KeyBundles: 'v2KeyBundles',
  V2MailTemplates: 'V2MailTemplates',
  V2Notification: 'V2Notification',
  V2OrganizationSetting: 'V2OrganizationSetting',
  V2Reception: 'V2Reception',
  V2ReceptionData: 'V2ReceptionData',
  V2ReceptionGuest: 'V2ReceptionGuest',
  V2Manual: 'V2Manual',
  AboutBitkey: 'AboutBitkey',

  // V2Signage
  SignageEmployeeAttendance: 'signageEmployeeAttendance',
  SignageCheckInInformation: 'signageCheckInInformation',
  SignageTrafficCondition: 'signageTrafficCondition',
  FaceRegistration: 'FaceRegistration',
  TrafficConditions: 'trafficConditions',
  // bitface with 検温ソリューションが収集した検温データを閲覧できるか
  V2ReadTemperatureLogs: 'v2ReadTemperatureLogs',
  V2ServiceApplication: 'v2serviceApplication',

  BmMigrateAssistant: 'BmMigrateAssistant',
  BmMigrateTour: 'BmMigrateTour',

  //bitlock App
  V2BitlockApp: 'V2BitlockApp',
  V2ReceptionApp: 'V2ReceptionApp',

  // workhub App
  V2WorkhubAppOrganization: 'V2WorkhubAppOrganization', // アプリの組織タブの表示非表示
  WorkhubAppPersonalizedNfcCard: 'WorkhubAppPersonalizedNfcCard', // アプリのマイページから管理できるログインユーザー個人所有のNFCカード管理権限
  WorkhubAppDeviceSetting: 'WorkhubAppDeviceSetting', //アプリ向けデバイス権限
  WorkhubAppLinkageDeviceSetting: 'WorkhubAppLinkageDeviceSetting', //デバイス連携権限 reader,link共通
  // bitlock
  WorkhubAppInstallBitlockPro2: 'WorkhubAppInstallBitlockPro2',
  WorkhubAppInstallBitlockGate: 'WorkhubAppInstallBitlockGate',
  WorkhubAppUninstallBitlockSeries: 'WorkhubAppUninstallBitlockSeries',
  WorkhubAppFirmwareUpdateBitlockSeries: 'WorkhubAppFirmwareUpdateBitlockSeries',
  WorkhubAppViewLogsBitlockSeries: 'WorkhubAppViewLogsBitlockSeries',
  WorkhubAppSetUnlockPositionBitlockSeries: 'WorkhubAppSetUnlockPositionBitlockSeries',
  WorkhubAppAutoLockSettingBitlockSeries: 'WorkhubAppAutoLockSettingBitlockSeries',
  WorkhubAppTouchSensorSettingBitlockSeries: 'WorkhubAppTouchSensorSettingBitlockSeries',
  // bitreader
  WorkhubAppInstallBitreaderPlus: 'WorkhubAppInstallBitreaderPlus',
  WorkhubAppUninstallBitreaderSeries: 'WorkhubAppUninstallBitreaderSeries',
  WorkhubAppFirmwareUpdateBitreaderSeries: 'WorkhubAppFirmwareUpdateBitreaderSeries',
  WorkhubAppViewLogsBitreaderSeries: 'WorkhubAppViewLogsBitreaderSeries',
  WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries: 'WorkhubAppSwitchValidLockUnlockSettingBitreaderSeries',
  WorkhubAppEnableNfcSettingBitreaderSeries: 'WorkhubAppEnableNfcSettingBitreaderSeries',
  WorkhubAppEnableKeyPadSettingBitreaderSeries: 'WorkhubAppEnableKeyPadSettingBitreaderSeries',
  WorkhubAppEnableLedSettingBitreaderSeries: 'WorkhubAppEnableLedSettingBitreaderSeries',
  WorkhubAppBatteryAlertSettingBitreaderSeries: 'WorkhubAppBatteryAlertSettingBitreaderSeries',
  WorkhubAppPasscodeSettingBitreaderSeries: 'WorkhubAppPasscodeSettingBitreaderSeries',
  // bitlink
  WorkhubAppInstallBitlinkSeries: 'WorkhubAppInstallBitlinkSeries',
  WorkhubAppUninstallBitlinkSeries: 'WorkhubAppUninstallBitlinkSeries',
  WorkhubAppFirmwareUpdateBitlinkSeries: 'WorkhubAppFirmwareUpdateBitlinkSeries',
  WorkhubAppEnableBeaconSettingBitlinkSeries: 'WorkhubAppEnableBeaconSettingBitlinkSeries',
  WorkhubAppChangeWiFiConnectionSettingBitlinkSeries: 'WorkhubAppChangeWiFiConnectionSettingBitlinkSeries',

  Report: 'report',
  SendMailJob: 'sendMailJob',
  AppKeys: 'AppKeys',
  FaceRecognitionStatus: 'faceRecognitionStatus',
  InviteMailSettingManager: 'InviteMailSettingManager',

  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'ThirdPlaceBceSetting',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'V2MultiTenantOwner',
  V2MultiTenantOwnerContract: 'V2MultiTenantOwnerContract',
  V2MultiTenantOwnerContractPlan: 'V2MultiTenantOwnerContractPlan',

  NotificationSetting: 'NotificationSetting',

  DataImport: 'DataImport',
  ForBitkey: 'ForBitkey',
  V2Dashboard: 'v2Dashboard',
  ThirdPlace: 'ThirdPlace',
  ThirdPlaceContract: 'ThirdPlaceContract',
  ThirdPlaceContractPlans: 'ThirdPlaceContractPlans',
  ThirdPlaceUpdateRules: 'ThirdPlaceUpdateRules',
  ThirdPlaceCancelRules: 'ThirdPlaceCancelRules',
  ThirdPlaceOptions: 'ThirdPlaceOptions',
  ThirdPlaceBilling: 'ThirdPlaceBilling',
  ThirdPlaceReceivedMoney: 'ThirdPlaceReceivedMoney',
  ThirdPlaceRefund: 'ThirdPlaceRefund',
  ThirdPlaceClaimCycles: 'ThirdPlaceClaimCycles',
  ThirdPlaceInvoice: 'ThirdPlaceInvoice',
  ThirdPlaceGuest: 'ThirdPlaceGuest',
  ThirdPlaceCustomer: 'ThirdPlaceCustomer',
  ThirdPlacePaymentMethod: 'ThirdPlacePaymentMethod',
  ThirdPlacePassSite: 'ThirdPlacePassSite',
  LocationInformationCSVOutput: 'LocationInformationCSVOutput',
  Application: 'Application',
  SecuritySetting: 'SecuritySetting',

  DropInPlans: 'DropInPlans',
  DropInPricePlans: 'DropInPricePlans',

  SpaceAuthoritySetting: 'spaceAuthoritySetting',
  WorkBooth: 'WorkBooth',
  AzbilCloudOperation: 'AzbilCloudOperation',
  TabletOperation: 'TabletOperation',
  DeleteFaceInformationForGuest: 'DeleteFaceInformationForGuest',
  FMDashboard: 'FMDashboard',
  OutlookReservationSyncHistories: 'outlookReservationSyncHistories',
  GoogleCalendarSyncHistories: 'GoogleCalendarSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  ApplicantTaskList: 'ApplicantTaskList',
  ApproverTaskList: 'ApproverTaskList',
  ApplicationFormList: 'ApplicationFormList',
} as const;
export type Feature = ValueOf<typeof Feature>;

export const FeatureGroup = {
  Announcement: 'Announcement',
  Dashboard: 'Dashboard',
  CompanyInformation: 'CompanyInformation',
  Subscriptions: 'Subscriptions',
  BuildingManagement: 'BuildingManagement',
  Department: 'Department',
  Bitlock: 'Bitlock',
  Bitreader: 'Bitreader',
  Tablet: 'Tablet',
  CameraSecurity: 'CameraSecurity',
  CloudCamera: 'CloudCamera',
  Location: 'Location',
  Heatmap: 'Heatmap',
  Locker: 'Locker',
  Shelf: 'Shelf',
  Cabinet: 'Cabinet',
  Face: 'Face',
  Nfc: 'Nfc',
  KeyConditionTemplate: 'KeyConditionTemplate',
  AttendanceEntryExit: 'AttendanceEntryExit',
  Member: 'Member',
  Guest: 'Guest',
  Authority: 'Authority',
  Reception: 'Reception',
  ReservationCalendar: 'ReservationCalendar',
  ReservationCalendarAddPeopleCsv: 'reservationCalendarAddPeopleCsv',
  CoworkingReservationCalendar: 'CoworkingReservationCalendar',
  // これは検証終わったら消す
  ReservationCalendar_bk: 'ReservationCalendar_bk',
  SystemLinkage: 'SystemLinkage',
  CongestionMap: 'CongestionMap',
  WifiConnector: 'WifiConnector',
  Bitlink: 'Bitlink',
  Events: 'Events',
  AntiPassBack: 'AntiPassBack',
  V2: 'V2', // 暫定
  V2Spaces: 'V2Spaces',
  V2Workspot: 'V2Workspot',
  V2Layouts: 'V2Layouts',
  V2SecurityDevices: 'V2SecurityDevices',
  V2SecurityCards: 'V2SecurityCards',
  V2SecurityCardGroups: 'V2SecurityCardGroups',
  V2Equipment: 'V2Equipment',
  V2Locker: 'V2Locker',
  V2MemberManagement: 'V2MemberManagement',
  FaceRegistration: 'FaceRegistration',
  V2VisitorManagement: 'V2VisitorManagement',
  V2VisitorGroupManagement: 'V2VisitorGroupManagement',
  V2CastManagement: 'V2CastManagement',
  V2CustomerManagement: 'V2CustomerManagement',
  V2AccessControl: 'V2AccessControl',
  V2MailTemplates: 'V2MailTemplates',
  V2Notification: 'V2Notification',
  V2OrganizationSetting: 'V2OrganizationSetting',
  V2Reception: 'V2Reception',
  V2Manual: 'V2Manual',
  V2ServiceApplication: 'V2ServiceApplication',
  AboutBitkey: 'AboutBitkey',
  V2Signage: 'V2Signage',
  TrafficConditions: 'TrafficConditions',
  V2LocationSearch: 'V2LocationSearch',
  V2EmployeesAttendanceReport: 'V2EmployeesAttendanceReport',

  BmMigrateAssistant: 'BmMigrateAssistant',
  BmMigrateTour: 'BmMigrateTour',

  // bitlock APPを利用する組織はこれをonにする
  V2BitlockApp: 'V2BitlockApp',

  //受付タブレットを使用する組織はonにする
  V2ReceptionApp: 'V2ReceptionApp',

  // 開発用
  Development: 'Development',

  // ThirdPlace利用組織
  ThirdPlace: 'ThirdPlace',
  Report: 'Report',

  // 招待メールを編集するためのアクティベーション、基本的には必要ない限り使用しないこと
  InviteMailSettingManager: 'InviteMailSettingManager',

  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'ThirdPlaceBceSetting',

  DataImport: 'DataImport',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'V2MultiTenantOwner',

  ForBitkey: 'ForBitkey',
  // beacon向け
  // 個人に対しての制御はする必要がないので、activationだけで制御
  Beacon: 'Beacon',
  V2Dashboard: 'v2Dashboard',

  //申請
  Application: 'Application',

  // Googleカレンダー連携のサービス
  GoogleCalendarSync: 'GoogleCalendarSync',

  // IPアドレス設定
  SecuritySetting: 'SecuritySetting',

  //スペース制御
  SpaceAuthoritySetting: 'SpaceAuthoritySetting',

  // ワークブース
  WorkBooth: 'WorkBooth',

  AzbilCloudOperation: 'AzbilCloudOperation',

  // RoomSupportやReceptionをworkhubから操作できる
  TabletOperation: 'TabletOperation',

  // 外部サービス連携
  LinkageSetting: 'LinkageSetting',

  // SSO連携
  SSOSetting: 'SSOSetting',

  // 勤怠
  // TODO: 出社状況画面の扱いをどうするか検討
  Attendance: 'Attendance',

  // 顔情報削除
  DeleteFaceInformationForGuest: 'DeleteFaceInformationForGuest',

  // ファシリティマネジメントダッシュボード
  FMDashboard: 'FMDashboard',
  OutlookReservationSyncHistories: 'OutlookReservationSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: 'VisitApplication',
} as const;
export type FeatureGroup = ValueOf<typeof FeatureGroup>;

export type ActivationGroup = FsActivation['activationGroup'];

// パッケージングされた機能群の考え方で定義。
// まずは管理画面でしか制御に使わない。アプリはあと。
// ○○パッケージを契約してたらこれらを使えます、的なノリ
// MECEは気にしない（基本的にどれかあったらいくつかが使えるようになるイメージで、n:nの関係にする）
export const ActivationGroup = {
  // 共通機能も使えないような制御のためにcommonも作る。coworkingテナントは空間が専用のビューになるとか、今後お試し期間やるとか、色々ありうるので
  Common: 'common',

  // 提供先業種でパッケージングされている機能群 (売り方)
  Office: 'office',

  // 業種とは違うけど、同じように利用方法でのグルーピング
  CameraSecurity: 'cameraSecurity',
  CloudCamera: 'cloudCamera',

  BuildingControl: 'buildingControl',
  Workspot: 'workspot',

  // coworkingテナント用機能群
  CoworkingTenant: 'coworkingTenant',

  // 設置されたらactivate、みたいにしたいやつ。activateされるまでは設置機能は出るけど一覧画面とかは出ないイメージ。
  // 設置のコントロールは必要があれば別途（はじめて購入したらactivateみたいな）
  Bitlock: 'bitlock',
  Bitreader: 'bitreader', // これかビルコントロールのどちらかを有効にするとNFCもついてくる感じ
  WifiConnector: 'wifiConnector',
  Bitlink: 'bitlink',
  Locker: 'locker',
  Shelf: 'shelf',

  // 契約に基づいて個別にActivateされるもの
  Reception: 'reception', // 受付システムを契約してる場合
  V2Reception: 'v2reception', // 受付システムを契約してる場合
  Face: 'face', // 顔認証システムを契約してる場合
  /** @deprecated 2023年7月現在、`reservationCalendar`さえ有効にすれば予約機能が解放されるという運用なので不要になる。削除予定 */
  Calendar: 'calendar',
  /** @deprecated 2023年7月現在、`reservationCalendar`さえ有効にすれば予約機能が解放されるという運用なので不要になる。削除予定 */
  Reservation: 'reservation',

  // うちの都合で出し分けるやつ
  Member: 'member',
  Events: 'events',
  Layouts: 'layouts',
  Equipment: 'equipment',
  MemberManagement: 'memberManagement',
  FaceRegistration: 'faceRegistration',
  VisitorManagement: 'visitorManagement',
  VisitorGroupManagement: 'visitorGroupManagement',
  CastManagement: 'castManagement',
  CustomerManagement: 'customerManagement',
  ReservationCalendar: 'reservationCalendar',
  ReservationCalendar_bk: 'reservationCalendar_bk',
  CoworkingReservationCalendar: 'coworkingReservationCalendar',
  Announcement: 'announcement',
  Manual: 'manual',
  V2ServiceApplication: 'v2serviceApplication',
  V2SecurityCardGroups: 'v2securityCardGroups', //現時点ではbM移行組織
  AboutBitkey: 'aboutBitkey',
  V2Signage: 'v2Signage',
  TrafficConditions: 'trafficConditions',

  BmMigrateAssistant: 'bMmigrateAssistant',
  BmMigrateTour: 'bMmigrateTour',
  V2Notification: 'v2Notification',
  V2OrganizationSetting: 'v2OrganizationSetting',

  V2BitlockApp: 'v2BitlockApp',
  V2ReceptionApp: 'v2ReceptionApp',

  Report: 'report',
  InviteMailSettingManager: 'inviteMailSettingManager',
  // workhubとBCEをつなげるための設定用Activation、基本的には必要ない限り使用しないこと
  ThirdPlaceBceSetting: 'thirdPlaceBceSetting',

  DataImport: 'dataImport',
  ForBitkey: 'forBitkey',
  // beacon向けWSelectPlan
  // 個人に対しての制御はする必要がないので、activationだけで制御
  Beacon: 'Beacon',
  V2Dashboard: 'v2Dashboard',
  V2EmployeesAttendanceReport: 'V2EmployeesAttendanceReport',
  // ある程度パッケージングした上でDeactivationとか作ってもいいかなと思ったけど複雑になるからやめる // 除外設定入れないと思いきったパッケージングができなくてつらそう
  ThirdPlace: 'thirdPlace',

  Application: 'application',

  /**
   * V2マルチテナント オーナー用のActivation
   * ThirdPlaceで契約しているエリアに対して共用表示が表示されるようになったりします
   */
  V2MultiTenantOwner: 'v2MultiTenantOwner',

  GoogleCalendarSync: 'googleCalendarSync',

  SecuritySetting: 'SecuritySetting',

  MultiLockDevices: 'MultiLockDevices',

  SpaceAuthoritySetting: 'spaceAuthoritySetting',
  WorkBooth: 'workBooth',

  AzbilCloudOperation: 'azbilCloudOperation',

  TabletOperation: 'tabletOperation',

  Attendance: 'attendance',
  Wowtalk: 'wowtalk',
  Pifaa: 'pifaa',
  Jobcan: 'jobcan',

  FMDashboard: 'FMDashboard',

  SSOSetting: 'SSOSetting',

  AntiPassBack: 'antiPassBack',
  OutlookReservationSyncHistories: 'outlookReservationSyncHistories',

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: 'visitApplication',
} as const satisfies Record<string, ActivationGroup>;

// 本命の定義
// featureGroupを有効化するために、activationGroupのどれかが有効になっていたらOK、という作り。空配列は未リリース。
// ただこいつらは本来APIに対しても有効にする必要があるので、画面というより機能という観点が必要。
export const ActivationFeatureGroupMap: {[K in FeatureGroup]: ActivationGroup[]} = {
  // 普通に共通
  Announcement: [ActivationGroup.Announcement],
  Authority: [ActivationGroup.Common, ActivationGroup.Member, ActivationGroup.MemberManagement],

  Bitlock: [ActivationGroup.Bitlock],
  Bitreader: [ActivationGroup.Bitreader],
  BuildingManagement: [ActivationGroup.Common],
  ReservationCalendar: [ActivationGroup.ReservationCalendar], // 新しいほう
  ReservationCalendar_bk: [ActivationGroup.ReservationCalendar_bk], // 既存動作確認用
  CoworkingReservationCalendar: [ActivationGroup.CoworkingReservationCalendar], // コワーキングテナント用
  CameraSecurity: [ActivationGroup.CameraSecurity],
  CloudCamera: [ActivationGroup.CloudCamera],
  CompanyInformation: [], // まだ出さない
  CongestionMap: [ActivationGroup.CameraSecurity], // readerとかフラッパーゲートとかでも管理できたりするからCameraSecurityではないけど一旦。
  AttendanceEntryExit: [ActivationGroup.CameraSecurity], // 名前がヤバイ
  Dashboard: [], // まだ出さない
  Department: [ActivationGroup.Office, ActivationGroup.Member], // そのうちtenantも？
  Face: [ActivationGroup.Face],
  Guest: [ActivationGroup.Common],
  Member: [ActivationGroup.Common, ActivationGroup.Member, ActivationGroup.CoworkingTenant],
  Heatmap: [],
  KeyConditionTemplate: [],
  Location: [ActivationGroup.Common],
  Locker: [ActivationGroup.Locker],
  Shelf: [ActivationGroup.Shelf],
  Cabinet: [],
  Nfc: [ActivationGroup.Bitreader],
  Reception: [ActivationGroup.Reception],

  Subscriptions: [], // まだ出さない
  SystemLinkage: [], // まだ出さない
  Tablet: [ActivationGroup.Face],
  WifiConnector: [ActivationGroup.WifiConnector], // とりあえず不要なユーザーには出したくないのでCommonにはしない。コネクタ使うユーザーだけ。
  Bitlink: [ActivationGroup.Bitlink],
  Events: [ActivationGroup.Events],
  TrafficConditions: [ActivationGroup.TrafficConditions],
  V2LocationSearch: [ActivationGroup.ReservationCalendar, ActivationGroup.Beacon],
  V2: ['V2' as ActivationGroup],
  Development: [],
  // [FeatureGroup.Layout]: ['layout' as ActivationGroup], // TODO serverside
  V2Spaces: [ActivationGroup.BuildingControl],
  V2Workspot: [ActivationGroup.Workspot], // 暫定
  V2Layouts: [ActivationGroup.Layouts],
  AntiPassBack: [ActivationGroup.AntiPassBack],
  V2SecurityDevices: [ActivationGroup.BuildingControl],
  V2SecurityCards: [ActivationGroup.BuildingControl],
  V2SecurityCardGroups: [ActivationGroup.V2SecurityCardGroups],
  V2Equipment: [ActivationGroup.Equipment],
  V2MemberManagement: [ActivationGroup.MemberManagement],
  FaceRegistration: [ActivationGroup.FaceRegistration],
  V2VisitorManagement: [ActivationGroup.VisitorManagement],
  V2VisitorGroupManagement: [ActivationGroup.VisitorGroupManagement], //初期ではv1-v2移行向けの機能のため一旦分割
  V2CastManagement: [ActivationGroup.CastManagement],
  V2CustomerManagement: [ActivationGroup.CustomerManagement],
  V2AccessControl: [ActivationGroup.BuildingControl],
  V2Locker: [ActivationGroup.Locker],
  V2MailTemplates: [ActivationGroup.ReservationCalendar],
  V2Notification: [ActivationGroup.V2Notification],
  V2OrganizationSetting: [ActivationGroup.V2OrganizationSetting],
  V2Reception: [ActivationGroup.V2Reception],
  V2Manual: [ActivationGroup.Manual],
  V2ServiceApplication: [ActivationGroup.V2ServiceApplication], //同上、テナント組織側にしか付与されない
  AboutBitkey: [ActivationGroup.BmMigrateTour, ActivationGroup.MemberManagement],
  V2Signage: [ActivationGroup.V2Signage],
  V2EmployeesAttendanceReport: [ActivationGroup.V2EmployeesAttendanceReport],

  BmMigrateAssistant: [ActivationGroup.BmMigrateAssistant],
  BmMigrateTour: [ActivationGroup.BmMigrateTour],
  V2BitlockApp: [ActivationGroup.V2BitlockApp],
  V2ReceptionApp: [ActivationGroup.V2ReceptionApp],
  Report: [ActivationGroup.Report],
  InviteMailSettingManager: [ActivationGroup.InviteMailSettingManager],
  ThirdPlaceBceSetting: [ActivationGroup.ThirdPlaceBceSetting],
  ForBitkey: [ActivationGroup.ForBitkey],
  Beacon: [ActivationGroup.Beacon],
  v2Dashboard: [ActivationGroup.V2Dashboard],
  DataImport: [ActivationGroup.DataImport],

  V2MultiTenantOwner: [ActivationGroup.V2MultiTenantOwner],
  Application: [ActivationGroup.Application],

  ThirdPlace: [ActivationGroup.ThirdPlace],
  GoogleCalendarSync: [ActivationGroup.GoogleCalendarSync],
  SecuritySetting: [ActivationGroup.SecuritySetting],
  SpaceAuthoritySetting: [ActivationGroup.SpaceAuthoritySetting],
  WorkBooth: [ActivationGroup.WorkBooth],
  AzbilCloudOperation: [ActivationGroup.AzbilCloudOperation],
  TabletOperation: [ActivationGroup.TabletOperation],

  LinkageSetting: [ActivationGroup.V2Reception, ActivationGroup.Attendance, ActivationGroup.Pifaa],
  SSOSetting: [ActivationGroup.SSOSetting],
  Attendance: [ActivationGroup.Attendance],
  DeleteFaceInformationForGuest: [ActivationGroup.VisitorManagement],
  FMDashboard: [ActivationGroup.FMDashboard],
  reservationCalendarAddPeopleCsv: [ActivationGroup.ReservationCalendar],
  OutlookReservationSyncHistories: [ActivationGroup.OutlookReservationSyncHistories],

  // ラキールWFの来訪申請メニュー
  // 2024/11/29時点で京セラ様利用のみを想定
  VisitApplication: [ActivationGroup.VisitApplication],
};
