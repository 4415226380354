import {createBrowserHistory, createRouter, type ErrorRouteComponent, RouterProvider} from '@tanstack/react-router';
import {type FC, useEffect} from 'react';

import {routeTree} from './routeTree.gen';

/**
 * TanStack Router の内側でエラーが起きた時に再スローすることでデフォルトのエラー画面を表示しないようにする
 * またエラーを外側にスローすることで何かしら対処が行われることを期待する.
 * 例えばモジュールロード関連のエラーは Vite が捕捉して vite:preloadError イベントを発行する仕組みがあるため, そちらを利用する.
 *
 * FIXME: 一方でユーザにとっては真っ白な画面が表示されることがあるので, 復帰不能なエラーが発生したときのエラー画面を用意しておいたほうが良い
 */
const ErrorBoundary: ErrorRouteComponent = ({error}): never => {
  throw error;
};

export const history = createBrowserHistory();

const router = createRouter({
  history,
  routeTree,
  defaultErrorComponent: ErrorBoundary,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const Router: FC = () => {
  useEffect(() => {
    if (!window.gtag || !import.meta.env.VITE_GA_ID) return;

    window.gtag('config', import.meta.env.VITE_GA_ID, {page_path: history.location.pathname});

    return history.subscribe(({location}) => {
      window.gtag('event', 'page_view', {page_path: location.pathname});
    });
  }, []);

  return <RouterProvider router={router} />;
};
